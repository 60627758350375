<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <filter-data :filter-action="getDataList" />
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(customer)="data">
          {{ data.item.customer }}
          <div class="text-primary font-small-2">
            {{ data.item.company_name }}
          </div>
        </template>
        <template #cell(sdate)="data">
          {{ moment(data.item.sdate).format('ll') }} {{ data.item.stime }}
          <div class="text-warning font-small-2">
            {{ moment().diff(moment(data.item.sdate + ' ' + data.item.stime),'hours') }} saat önce
          </div>
        </template>
        <template #cell(control)="data">
          <div v-if="!data.item.id_com_crm">

            <b-button
              variant="primary"
              size="sm"
              @click="showAssigmentModal(data.item)"
            >
              <feather-icon icon="EditIcon" />
              İşlemler
            </b-button>
          </div>
          <div v-else>
            <b-link
              :href="'/crm/view/' + data.item.id_com_crm"
              target="_blank"
              class="text-primary"
            >Talep {{ data.item.id_com_crm }} numaralı başvuru ile kapatıldı.</b-link>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <b-modal
      v-model="assigmentModal.status"
      title="İşlemler"
      no-close-on-backdrop
      centered
      size="lg"
    >
      <contact :data-item="assigmentModal.item" />
      <b-form-group class="mt-2">
        <b-form-radio-group
          id="btn-radios-1"
          v-model="assigmentModal.modeButton"
          size="sm"
          button-variant="outline-primary"
          :options="optionsRadio"
          buttons
          name="radios-btn-default"
          @input="resetModalData"
        />
      </b-form-group>
      <template v-if="assigmentModal.modeButton === 'contact'">
        <b-form-group
          label="Atanacak Kullanıcı"
          label-for="id_com_user"
          class="mt-2"
        >
          <v-select
            id="id_com_user"
            v-model="assigmentModal.id_com_user"
            placeholder="Atanacak Kullanıcı"
            :options="users"
            label="title"
            :reduce="user => user.id"
          >
            <template v-slot:option="option">
              {{ option.title }}
              <div v-if="option.user_type">
                <small class="text-warning">{{ option.user_type }}</small>
                <small class="text-muted"> / {{ option.department }}</small>
                <small class="text-muted"> / {{ option.brand }}</small>
              </div>
            </template>
            <template v-slot:selected-option="option">
              {{ option.title }} <small class="text-warning ml-1"> {{ option.user_type }}</small>
            </template>
          </v-select>
        </b-form-group>
      </template>
      <template v-else>
        <b-row class="d-flex align-items-center justify-content-center">
          <b-col>
            <b-form-group
              label="Başvuru Numarası"
              label-for="id_com_crm"
            >
              <b-form-input
                id="id_com_crm"
                v-model="assigmentModal.id_com_crm"
                placeholder="Giriniz"
              />
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              variant="primary"
              size="sm"
              :to="'/crm/add/' + assigmentModal.id_com_customer"
              target="_blank"
            >
              Başvuru Oluştur
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <small class="text-warning">Web talebinin başvuru ile kapatılabilmesi için "Başvuru Oluştur" butonuna tıklayarak başvuru oluşturulduktan sonra başvuru numarasını "Başvuru Numarası" alanına girmelisiniz.</small>
          </b-col>
        </b-row>
      </template>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="assigmentModal.status = false"
        >
          Kapat
        </b-button>
        <b-button
          :disabled="!assigmentModal.id_com_user && !assigmentModal.id_com_crm"
          variant="success"
          @click="saveUserAssigment"
        >
          <feather-icon icon="SaveIcon" />
          Kaydet
        </b-button>

      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BFormGroup, BModal, BFormRadioGroup, BFormInput, BRow, BCol, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import FilterData from '@/views/Crm/AssigmentsFilter/FilterData.vue'
import Contact from '@/views/Webforms/components/Contact.vue'

const tableName = 'com_contact'
export default {
  name: 'Index',
  components: {
    BFormInput,
    Contact,
    BFormGroup,
    vSelect,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BModal,
    BFormRadioGroup,
    BRow,
    BCol,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    FilterData,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      assigmentModal: {
        status: false,
        id: null,
        id_com_user: null,
        id_com_crm: null,
        id_com_customer: null,
        modeButton: 'contact',
        item: null,
      },
      optionsRadio: [
        { text: 'Danışman Atama', value: 'contact' },
        { text: 'Başvuru Oluşturma', value: 'crm' },
      ],
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ ADI',
        },
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'sdate',
          label: 'Tarih',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.sdate AS sdate`,
          `${tableName}.stime AS stime`,
          `${tableName}.id_com_user AS id_com_user`,
          `${tableName}.id_com_crm AS id_com_crm`,
          `${tableName}.id_com_customer AS id_com_customer`,
          `${tableName}.message AS message`,
          'com_customer.phone AS phone',
          'com_customer.email AS email',
          'com_brand.name as brand',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_user.name AS username',
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    filters() {
      return this.$store.getters['webformContact/filters']
    },
    dataList() {
      return this.$store.getters['webformContact/dataList']
    },
    dataCounts() {
      return this.$store.getters['webformContact/dataCounts']
    },
    saveData() {
      return this.$store.getters['webformContact/dataSaveStatus']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getUsers()
  },
  methods: {
    getDataList() {
      const where = {}
      if (this.filters.id_com_brand) {
        where['com_contact.id_com_brand'] = this.filters.id_com_brand
      }
      if (this.filters.id_com_user) {
        where['com_contact.id_com_user'] = this.filters.id_com_user
      }
      if (this.filters.status === 0) {
        where['com_contact.id_com_user'] = null
        where['com_contact.id_com_crm'] = null
      }
      if (this.filters.status === 1) {
        where['com_contact.id_com_user !='] = null
      }
      if (this.filters.status === 2) {
        where['com_contact.id_com_crm !='] = null
      }
      where['com_contact.id_com_interview'] = null
      this.dataQuery.where = where
      this.$store.dispatch('webformContact/getDataList', this.dataQuery)
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
          'com_user_type.title as user_type',
          'com_brand.name as brand',
          'com_department.title as department',
        ],
        where: {
          'com_user.status': 1,
          'com_user.webform': 1,
        },
      })
    },
    showAssigmentModal(data) {
      this.assigmentModal.id = data.id
      this.assigmentModal.id_com_user = data.id_com_user
      this.assigmentModal.item = data
      this.assigmentModal.id_com_crm = null
      this.assigmentModal.id_com_customer = data.id_com_customer
      this.assigmentModal.modeButton = 'contact'
      this.assigmentModal.status = true
    },
    saveUserAssigment() {
      this.$store.dispatch('webformContact/assigmentRequest', {
        id: this.assigmentModal.id,
        id_com_user: this.assigmentModal.id_com_user,
        id_com_crm: this.assigmentModal.id_com_crm,
      })
        .then(response => {
          if (response.status) {
            this.assigmentModal.id_com_user = null
            this.assigmentModal.id = null
            this.assigmentModal.id_com_crm = null
            this.assigmentModal.id_com_customer = null
            this.assigmentModal.modeButton = 'contact'
            this.assigmentModal.status = false
            this.getDataList()
          }
        })
    },
    resetModalData() {
      this.assigmentModal.id_com_crm = null
      this.assigmentModal.id_com_user = null
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
